import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import SearchNotes from "../components/searchNotes"
import Newsletter from "../components/newsletter"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Atul Prd"
    const { data, navigate, location } = this.props
    const posts = data.allMdx.edges
    const localSearchBlog = data.localSearchBlog

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Atul Pradhananga"
          keywords={[`Atul Pradhananga`, `Blog`, `Product Design`, `Branding`, `UX Design`, `Product Designer`, `UX/UI`, `App Design`, `Brand Identity`, `Nepal`, `Illustrator`]}
        />
        <img style={{ margin: 0, marginTop: 10  }} src="https://res.cloudinary.com/atulprd/image/upload/v1599187149/Atul-Pradhananga-Picture_icudmy.png" alt="Atul Pradhananga"/>
        <h2 style= {{ lineHeight: 1.3 , marginTop: `30px` }}>
        Hey, I'm Atul — I help companies define, design, and launch better digital products.
          </h2>
    <p>
    Here, I explore topics I'm interested in like product design, creator economy, productivity, lifestyle design, mental models, & more.
    </p>
    <p>
    If you're interested in these topics, you should sign up for my newsletter, where I share and discuss ideas, resources and questions to sharpen your thinking and change your perspective on design, business, & life. 
    </p>
    <Newsletter />
    <h2>Personal Favorites</h2>
    <li><b><a href={`https://www.atulprd.com/blog/what-makes-great-brands-great/`}>What Makes Great Brands Great</a></b> – thoughts on building a brand that matters.</li>
    <li><b><a href={`https://www.atulprd.com/blog/design-for-delight/`}>Designing for Delight</a></b> – how to design products that delight the users.</li>
    <li><b><a href={`https://www.atulprd.com/blog/how-to-think-like-sherlock-holmes`}>Think Like Sherlock Holmes</a></b> — my observation on how we, as UX professionals, can create a model for thought, for decision making, for how to structure, investigate, observe, and solve problems.</li>
    <li><b><a href={`https://www.atulprd.com/blog/you-call-it-writers-block-professionals-call-it-writing/`}>You call it writer's block. Professionals call it writing.</a></b> — a riff on writer's block.</li>
    <li><b><a href={`https://www.atulprd.com/blog/find-products-for-your-customers/`}>Find products for your customers</a></b> — on the importance of discovery phase.</li>
    <li><b><a href={`https://www.atulprd.com/blog/start-with-the-problem-in-mind/`}>Start with the problem</a></b> — the secret to a good research begins by formulating an interesting problem. </li>
    <li><b><a href={`https://www.atulprd.com/blog/how-to-measure-intangibles/`}>How to measure intangibles?</a></b> — on deconstructing the construct.</li>
   <h2>Recent writings</h2>
        <SearchNotes
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
        />
        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
   <br/>
    <h2>Get in touch</h2>
    <p>
    I’m always happy to meet people building interesting things. Email me at: <a href={`mailto:hi@atulprd.com`} target="_blank" rel="noopener noreferrer">hi@atulprd.com</a>
    </p>
    <br/>
        <div className="BlogSub">
        <h3>Sign up for my newsletter <span role="img" aria-label>💌</span> </h3>
        <p>My newsletter provides a behind-the-scenes look into what I'm working on and writing about. I frequently share some of my favorite articles I've read, as well as anything fascinating about design, business, & life. </p>
        <Newsletter/>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(
      sort: { 
        fields: [frontmatter___date],
        order: DESC }
        limit: 4
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
